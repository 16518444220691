body {
  margin: 0;
  overflow: overlay;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0,0,0,0.2);
  background-clip: content-box;
}